// Wards Updated 02/08/2022
// font update
@import "https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,800&display=swap";
@import "https://fonts.googleapis.com/css2?family=Mulish:wght@400;600;700;800;900&display=swap";

#div-gpt-ad-1589819585012-0 {
    display: block !important;
}

a.show-menu-item[data-wa-event-action="sale"],
a.show-menu-item[data-wa-event-action="sale"]:hover {
    color: #bd081c !important;
}

a[data-wa-event-label="clearance"] {
    color: #bd081c !important;
}

.top-categories-slot {
    background: #fff;
}

#main .home-bottom-first,
#main .home-bottom-second,
#main .home-bottom-third {
    background: #fff;
}

h1 {
    font-size: 24px;
    line-height: 27px;
}

footer {
    padding-top: 5px;
}

.footer-top-promo {
    background: none !important;
}

.hero-container h1,
.cat-banner h1 {
    font-size: 2.3rem;
}

div.headline2 {
    font-family: "nunito_sansextrabold", Arial, sans-serif;
    font-size: 1.5rem;
}

.product-set .product-desc ul {
    list-style-type: disc;
    list-style-position: inside;
}

.ui-tooltip {
    max-width: 90%;
}

#keyword_content {
    color: #f00;
    padding-bottom: 15px;
}

.openModal {
    cursor: pointer;
}

.hp-xUp .noWrap {
    white-space: nowrap;
}

.hp-xUp {
    text-align: center;
    padding: 20px 0;
}

.hp-xUp .cta {
    display: block;
    margin: 5px auto;
}

.hp-xUp .upCell {
    position: relative;
}

.hp-xUp a {
    font-family: Nunito, Arial, sans-serif;
    font-weight: 700;
    color: #3b3b3b;
    font-size: 18px;
    letter-spacing: 0.2px;
}

.hp-xUp picture {
    display: block;
}

.hp-xUp img {
    width: 100%;
    display: block;
}

.hp-xUp .upRowContainer {
    width: 100%;
    margin: 0 auto;
    max-width: 1210px;
    display: flex;
    flex-wrap: row wrap;
    justify-content: center;
}

.hp-threeUp .upCell {
    width: 100%;
    margin: 15px 0;
    display: inline-block;
    max-width: 600px;
}

.hp-threeUp .upRowContainer {
    flex-direction: column;
    align-items: center;
}

.hp-twoUp .upCell {
    width: 100%;
    margin: 15px 0;
    display: inline-block;
}

.hp-twoUp .upRowContainer {
    flex-direction: column;
    align-items: center;
}

.brand-carousel {
    margin: 15px auto 52px;
}

.brand-carousel .slick-slide {
    background-color: #f3f3f3;
    padding: 10px 15px 30px;
    box-shadow: none;
}

.brand-carousel .product-tile {
    text-align: center;
    margin: 0 auto;
}

.brand-carousel .product-tile .logo {
    width: auto;
    margin: 0 auto;
    min-height: 80px;
}

.brand-carousel .product-tile .review-product-name {
    font-family: "Nunito Sans", Arial, sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #393939;
    max-width: 180px;
    margin: 15px auto 0;
}

.brand-carousel .product-tile .rating {
    margin: 15px 0 0;
}

.brand-carousel .product-tile .star-rating {
    display: inline;
    height: 18px;
}

.brand-carousel .product-tile .review-headline {
    font-family: Mulish, sans-serif;
    font-size: 24px;
    line-height: 26px;
    color: #393939;
    font-weight: 800;
    max-width: 220px;
    margin: 15px auto 0;
}

.brand-carousel .product-tile .review-copy {
    font-family: "Nunito Sans", Arial, sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #393939;
    max-width: 224px;
    margin: 15px auto 0;
}

.brand-carousel .product-tile .reviwer-name {
    font-family: "Nunito Sans", Arial, sans-serif;
    font-weight: 600;
    font-size: 13px;
    color: #393939;
    max-width: 200px;
    text-transform: uppercase;
    margin: 15px auto 0;
}

.brand-carousel .slick-track .slick-slide img {
    width: auto;
}

.recommendations {
    padding: 1.25rem 0 0.9375rem;
    position: relative;
    width: calc(100% + 0.125rem);
}

.recommendations .slick-prev {
    left: 0.19rem;
}

.recommendations .slick-next {
    right: 0.25rem;
}

.recommendations .grid-tile .slick-slide .slick-active {
    width: 360px;
}

.recommendations-container {
    margin-left: 0.5%;
    margin-right: 1%;
}

.home-bottom-first .recommendations-container {
    margin-left: 0.5%;
    margin-right: 1%;
}

.home-bottom-first .recommendations {
    padding: 1.25rem 0 0.9375rem;
    position: relative;
    width: calc(100% + 0.125rem);
}

.home-bottom-first .recommendations .slick-prev {
    left: 0.19rem;
}

.home-bottom-first .recommendations .slick-next {
    right: 0.25rem;
}

.home-bottom-first .recommendations .grid-tile .slick-slide .slick-active {
    width: 360px;
}

.home-bottom-first .recommendations .grid-tile .product-tile {
    margin-left: auto !important;
}

.cross-sell ul li.grid-tile {
    margin-left: 0.4378rem !important;
}

.recommendations .slick-list {
    width: 280px;
    margin: 0 auto;
}

.recommendations .product-tile .product-badge {
    left: 0;
}

.recommendations .product-tile .product-image {
    margin: 0 auto 0.625rem;
}

.recommendations .product-tile .product-name {
    margin: 0 auto;
    width: 92%;
    max-width: 260px;
}

.recommendations .product-swatches {
    margin: 0 auto;
    max-width: 260px;
    text-align: left;
}

.recommendations .product-tile .product-pricing {
    margin: 0.4375rem auto 0;
    width: 260px;
    text-align: left;
}

.recommendations .grid-tile {
    background: #fff;
    box-shadow: 0 0 9px 0 silver;
    width: 18.75rem;
    height: auto;
    padding: 0;
    margin: 0.3125rem 0.4375rem;
}

.nowrap {
    white-space: nowrap;
}

.br-m {
    display: none;
}

.br-l {
    display: none;
}

.br-xl {
    display: none;
}

.br-s {
    display: block;
}

@media screen and (min-width: 480px) {
    .ui-tooltip {
        max-width: 90%;
    }

    .brand-carousel {
        margin: 20px auto 60px;
    }

    .brand-carousel .product-tile .review-product-name {
        font-size: 15px;
    }

    .brand-carousel .product-tile .review-headline {
        max-width: 167px;
    }

    .br-s {
        display: none;
    }

    .br-l {
        display: none;
    }

    .br-xl {
        display: none;
    }

    .br-m {
        display: block;
    }
}

@media screen and (min-width: 768px) {
    div.headline2 {
        font-size: 1.875rem;
    }

    .hero-container h1,
    .cat-banner h1 {
        font-size: 1.725rem;
    }

    .hp-threeUp .upCell {
        width: calc((100% - 40px) / 3);
        max-width: 415px;
    }

    .hp-threeUp .upRowContainer {
        justify-content: space-between;
        max-width: 1280px;
        flex-direction: row;
        align-items: normal;
    }

    .hp-threeUp a {
        font-size: 22px;
    }

    .hp-twoUp .upCell {
        width: calc((100% - 20px) / 2);
    }

    .hp-twoUp .upRowContainer {
        justify-content: space-between;
        width: calc(100% - 40px);
        margin: 0 auto;
        max-width: 1280px;
        flex-direction: row;
        align-items: normal;
    }

    .hp-twoUp a {
        font-size: 22px;
    }

    .brand-carousel {
        margin: 22px auto 60px;
    }

    .brand-carousel .product-tile .review-product-name {
        font-size: 16px;
    }

    .brand-carousel .product-tile .review-headline {
        max-width: 220px;
    }

    .brand-carousel .slick-slide {
        padding: 10px 1vw 30px;
    }

    .recommendations {
        padding: 1.875rem 0 1.5625rem;
    }

    .recommendations-container {
        position: relative;
        margin-left: 8%;
        margin-right: 8%;
    }

    .recommendations .slick-prev {
        left: -1.9375rem;
    }

    .recommendations .slick-next {
        right: -2.5rem;
    }

    .recommendations .grid-tile {
        background: #fff;
        box-shadow: 0 0 9px 0 silver;
        width: 18.75rem;
        height: auto;
        padding: 0;
        margin: 0.3125rem 0.4375rem;
    }

    .recommendations .grid-tile .slick-slide .slick-active {
        width: 303px;
    }

    .recommendations .slick-list {
        width: calc(100% - 8%);
    }

    .home-bottom-first .recommendations {
        padding: 1.875rem 0 1.5625rem;
    }

    .home-bottom-first .recommendations-container {
        position: relative;
        margin-left: 8%;
        margin-right: 8%;
    }

    .home-bottom-first .recommendations .slick-prev {
        left: -1.9375rem;
    }

    .home-bottom-first .recommendations .slick-next {
        right: -2.5rem;
    }

    .home-bottom-first .recommendations .grid-tile {
        background: #fff;
        box-shadow: 0 0 9px 0 silver;
        width: 18.75rem;
        height: auto;
        padding: 0;
        margin: 0.3125rem 0.4375rem;
    }

    .home-bottom-first .recommendations .grid-tile .slick-slide .slick-active {
        width: 303px;
    }

    .br-s {
        display: none;
    }

    .br-m {
        display: none;
    }

    .br-xl {
        display: none;
    }

    .br-l {
        display: block;
    }
}

@media screen and (min-width: 1025px) {
    div.headline2 {
        font-size: 1.875rem;
    }

    h1 {
        font-size: 30px;
        line-height: 32px;
    }

    .brand-carousel {
        margin: 30px auto 60px;
    }

    .brand-carousel .slick-slide {
        padding: 10px 13px 30px;
    }

    .br-s {
        display: none;
    }

    .br-m {
        display: none;
    }

    .br-l {
        display: none;
    }

    .br-xl {
        display: block;
    }
}

@media (min-width: 1921px) {
    body > #wrapper {
        max-width: 1920px;
    }
}

#product-content {
    .rating-wrapper {
        .p-w-r {
            font-family: "Nunito Sans", Arial, sans-serif;
        }
    }
}
