// --------------------------------
//  COLORS
// --------------------------------
// names taken from style guide

$brand-primary: #d71921;
$brand-secondary: #bd081c;
$black: #000;
$dark-gray: #393939;
$medium-gray: #6e6e6e;
$light-gray: #c0c0c0;
$lighter-gray: #ebebeb;
$white: #fff;
$yellow: #ffe544;
$teal: #569d94;
$accent1: #0081a0; // used in account, badges
$accent2: #00647c;
$accent3: $brand-primary; // Add to Cart button
$accent4: $brand-secondary; // Add to Cart hover
$trans: transparent;

// consistent messaging colors
$error-color: #d41836;
$warning-color: #ffe544; // used in account
$success-color: #47807b; // used in account

$logo-width-small: 146px;
$logo-height-small: 18px;
$logo-width-tablet: 240px;
$logo-height-tablet: 28px;
$logo-width-desktop: 21%; // 295px in 1440
$logo-height-desktop: 35px;

// top banner
$promo-gradient-bg: linear-gradient(to left, #676767, $dark-gray);

// add to cart modal banner
$promo-gradient-bg-right: linear-gradient(to right, #676767, $dark-gray);

// Aliased variables used in global
$site-input-border-radius: 3px;
$site-btn-border-radius: 4px;

// Button Fonts
$button-font: $sans-serif-extrabold;

// offset for the header search per brand
$header-search-offset: -38px;
