// Import the styles generated from the SVG icons
@import "../compiled/svg";

// Styles apply to svg icons implemented via the svghelper module
.icon {
    display: inline-block;
    vertical-align: middle;
    fill: $icon-fill;

    // Icons grouped by extra class
    &.hoverable:hover {
        fill: $icon-hover-fill;
    }

    &.header-icons {
        fill: $icon-header-fill;

        &:hover {
            fill: $icon-header-hover-fill;
        }
    }

    &.footer-icons {
        &:hover {
            fill: $icon-social-hover-fill;
        }
    }

    // Individual icons
    &.logo {
        width: 146px;
        height: 18px;

        @include media-breakpoint-up(md) {
            width: 240px;
            height: 29px;
        }

        @include media-breakpoint-up(lg) {
            width: 295px;
            height: 35px;
        }
    }

    &.email-icon,
    &.close-icon,
    &.collapse,
    &.expand {
        fill: $medium-gray; // closest guide color to custom fills
    }

    &.arrow-down,
    &.arrow-up,
    &.arrow {
        fill: $icon-fill;
    }

    &.arrow.right {
        transform: rotate(180deg);
    }

    &.arrow.up {
        transform: rotate(90deg);
    }

    &.arrow.down {
        transform: rotate(-90deg);
    }

    &.checkmark.light {
        fill: $white;
    }

    &.nav-orders.add-to-cart-icon {
        fill: $white;
        width: 24px;
        height: 24px;
    }

    &.increase {
        fill: $icon-fill2;
    }

    &.decrease {
        fill: $icon-fill2;
    }
}
